import React from 'react';
import { Button } from 'reactstrap';
import TSTLogo from '../assets/logos/blue_logo.svg';

export const urlList = {
  signUp: { type: 'POST', endpoint: '/user/signup' },

  // Signup API

  // Login API
  login: { type: 'POST', endpoint: '/user/login' },
  getMe: { type: 'GET', endpoint: '/user/getMe' },

  // Analytics API
  analytics: { type: 'GET', endpoint: '/transaction/analytics/client' },

  // Reminder API
  getMeReminder: { type: 'GET', endpoint: '/transaction/dueReminder/:id' },

  // Client API
  getMeClients: { type: 'GET', endpoint: '/client' },
  addClient: { type: 'POST', endpoint: '/client' },
  getMeClient: { type: 'GET', endpoint: '/client/:id' },
  deleteClient: { type: 'DELETE', endpoint: '/client/:id' },
  editClient: { type: 'PATCH', endpoint: '/client/:id' },

  // Product API
  getMeProducts: { type: 'GET', endpoint: '/product' },
  addProduct: { type: 'POST', endpoint: '/product' },
  deleteProduct: { type: 'DELETE', endpoint: '/product/:id' },
  editProduct: { type: 'PATCH', endpoint: '/product/:id' },

  // Transaction & Instalment API
  getallTransaction: { type: 'GET', endpoint: '/transaction' },
  getallPaidTransaction: { type: 'GET', endpoint: '/transaction?type=paid' },
  addTransaction: { type: 'POST', endpoint: '/transaction' },
  deleteInstalment: { type: 'DELETE', endpoint: '/transaction/:id' },
  editInstalment: { type: 'DELETE', endpoint: '/transaction/:id' },
  deleteTransaction: { type: 'DELETE', endpoint: '/transaction/:id' },
  editTransaction: { type: 'PATCH', endpoint: '/transaction/:id' },
};

const textRanders = ({ value }) => <>{value}</>;
// const RouterLinkRanders = ({ value }) => (
//   <Link to={`/app${value.link}`}>{value.value}</Link>
// );

const ImageRanders = ({ value }) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = TSTLogo;
  }
  return (
    <div>
      <img className="list-item-pic" src={imageSrc} alt={value} />
    </div>
  );
};

const RenderActionButton = ({ value }) => {
  const { buttonLabel, id, onClick } = value;
  return (
    <div>
      <Button
        outline
        onClick={() => {
          onClick(id);
        }}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

// const RenderToggleButton = ({ value }) => {
//   const { checked, id, onClick } = value;
//   return (
//     <div>
//       <SwitchExamples
//         onChange={() => {
//           onClick(id);
//         }}
//         checked={checked}
//       />
//     </div>
//   );
// };
const CONSTANTS = {
  TABLE_ID: {
    add: 'modal.button',
    update: 'modal.update',
    addModal: 'advisor.add-new-modal-title',

    doctor: 'table.react-table-doctor',
    addDoctor: 'doctor.add-new-modal-title',
    editDoctor: 'doctor.edit-new-modal-title',
    doctorAvailability: 'table.react-table-doctorAvailability',
    addDoctorAvailability: 'doctorAvailability.add-new-modal-title',
    editDoctorAvailability: 'doctorAvailability.edit-new-modal-title',
    doctorPublishedPapers: 'table.react-table-doctorPublishedPapers',
    addDoctorPublishedPapers: 'doctorPublishedPapers.add-new-modal-title',
    editDoctorPublishedPapers: 'doctorPublishedPapers.edit-new-modal-title',
    doctorManagementFeedback: 'table.react-table-doctorManagementFeedback',

    // For Clietns
    addClientsButton: 'button.add.client',
    gigsClientsTitle: 'modal.client.title',
    instalmentClientsTitle: 'modal.client.instalment',

    // For Products
    addProductsButton: 'button.add.products',
    productsModalTitle: 'modal.products.title',
  },

  TABLE_HEADER: {
    CLIENTS_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Profile',
        accessor: 'profilePic',
        cellClass: 'list-item-pic w-10',
        Cell: ImageRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Email',
        accessor: 'email',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Mobile number',
        accessor: 'mobile',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Total Amount',
        accessor: 'paid',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Received',
        accessor: 'received',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Remaining',
        accessor: 'remaining',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'notify',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: '',
        accessor: 'action',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
    ],
    CLIENTS_TRANSACTION_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Time',
        accessor: 'time',
        cellClass: 'list-item-heading ',
        Cell: textRanders,
      },

      {
        Header: 'Transaction ID',
        accessor: 'trId',
        cellClass: 'list-item-heading ',
        Cell: textRanders,
      },
      {
        Header: 'Servcie Items',
        accessor: 'productData',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        cellClass: 'list-item-heading ',
        Cell: textRanders,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    CLIENTS_INSTALMENT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Time',
        accessor: 'time',
        cellClass: 'list-item-heading ',
        Cell: textRanders,
      },

      {
        Header: 'Transaction ID',
        accessor: 'trId',
        cellClass: 'list-item-heading ',
        Cell: textRanders,
      },
      {
        Header: 'Amount',
        accessor: 'totalAmount',
        cellClass: 'list-item-heading ',
        Cell: textRanders,
      },

      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    PRODUCTS_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Profile',
        accessor: 'photo',
        cellClass: 'list-item-pic w-10',
        Cell: ImageRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'HSN Code',
        accessor: 'HSNCode',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Price',
        accessor: 'amount',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    TRANSACTION_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Time',
        accessor: 'time',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Transaction ID',
        accessor: 'trId',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Servcie Items',
        accessor: 'productData',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
  },

  RIGHT_SIDEBAR_FIELD: {
    PRODUCT_MODAL: [
      {
        no: 0,
        Label: 'form.products.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.products.amount',
        name: 'amount',
        id: 'amount',
        type: 'number',
        required: true,
      },
      {
        no: 2,
        Label: 'form.products.code',
        name: 'HSNCode',
        id: 'HSNCode',
        type: 'text',
        required: true,
      },
      {
        no: 3,
        Label: 'form.products.profile',
        name: 'photo',
        id: 'photo',
        type: 'file',
        required: false,
      },
    ],
    CLIENTS_MODAL: [
      {
        no: 0,
        Label: 'form.client.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.client.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: false,
      },
      {
        no: 2,
        Label: 'form.client.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: true,
      },
      {
        no: 3,
        Label: 'form.client.altrmobile',
        name: 'alternateMobile',
        id: 'alternateMobile',
        type: 'number',
        required: false,
      },

      {
        no: 4,
        Label: 'form.client.profile',
        name: 'profilePic',
        id: 'profilePic',
        type: 'file',
        required: false,
      },
    ],
    CLIENTS_TRANSACTION_MODAL: [
      {
        no: 1,
        Label: 'form.client_tra.amount',
        name: 'totalAmount',
        id: 'totalAmount',
        type: 'number',
        required: false,
      },
      {
        no: 1,
        Label: 'form.client_tra.receive',
        name: 'receivedByClient',
        id: 'receivedByClient',
        type: 'number',
        required: false,
      },

      {
        no: 2,
        Label: 'form.client_tra.paints',
        name: 'paints',
        id: 'paints',
        value: 'Paints',
        type: 'checkbox',
        required: false,
      },
      {
        no: 3,
        Label: 'form.client_tra.hardware',
        name: 'hardware',
        id: 'hardware',
        value: 'Hardware',
        type: 'checkbox',
        required: false,
      },
      {
        no: 4,
        Label: 'form.client_tra.plumbing',
        name: 'plumbing ',
        id: 'plumbing',
        value: 'Plumbing',
        type: 'checkbox',
        required: false,
      },
    ],
    EDIT_CLIENTS_TRANSACTION_MODAL: [
      {
        no: 1,
        Label: 'form.client_tra.amount',
        name: 'totalAmount',
        id: 'totalAmount',
        type: 'number',
        required: false,
      },

      {
        no: 2,
        Label: 'form.client_tra.paints',
        name: 'paints',
        id: 'paints',
        value: 'Paints',
        type: 'checkbox',
        required: false,
      },
      {
        no: 3,
        Label: 'form.client_tra.hardware',
        name: 'hardware',
        id: 'hardware',
        value: 'Hardware',
        type: 'checkbox',
        required: false,
      },
      {
        no: 4,
        Label: 'form.client_tra.plumbing',
        name: 'plumbing ',
        id: 'plumbing',
        value: 'Plumbing',
        type: 'checkbox',
        required: false,
      },
    ],
    TRANSACTION_MODAL: [
      {
        no: 0,
        Label: 'Mobile Number',
        name: 'mobile',
        id: 'mobile',
        type: 'addContact',
        required: true,
      },
      {
        no: 1,
        Label: 'form.client_tra.amount',
        name: 'totalAmount',
        id: 'totalAmount',
        type: 'number',
        required: false,
      },
      {
        no: 1,
        Label: 'form.client_tra.receive',
        name: 'receivedByClient',
        id: 'receivedByClient',
        type: 'number',
        required: false,
      },

      {
        no: 2,
        Label: 'form.client_tra.paints',
        name: 'paints',
        id: 'paints',
        value: 'Paints',
        type: 'checkbox',
        required: false,
      },
      {
        no: 3,
        Label: 'form.client_tra.hardware',
        name: 'hardware',
        id: 'hardware',
        value: 'Hardware',
        type: 'checkbox',
        required: false,
      },
      {
        no: 4,
        Label: 'form.client_tra.plumbing',
        name: 'plumbing ',
        id: 'plumbing',
        value: 'Plumbing',
        type: 'checkbox',
        required: false,
      },
    ],
    EDIT_TRANSACTION_MODAL: [
      {
        no: 1,
        Label: 'form.client_tra.amount',
        name: 'totalAmount',
        id: 'totalAmount',
        type: 'number',
        required: false,
      },

      {
        no: 2,
        Label: 'form.client_tra.paints',
        name: 'paints',
        id: 'paints',
        value: 'Paints',
        type: 'checkbox',
        required: false,
      },
      {
        no: 3,
        Label: 'form.client_tra.hardware',
        name: 'hardware',
        id: 'hardware',
        value: 'Hardware',
        type: 'checkbox',
        required: false,
      },
      {
        no: 4,
        Label: 'form.client_tra.plumbing',
        name: 'plumbing ',
        id: 'plumbing',
        value: 'Plumbing',
        type: 'checkbox',
        required: false,
      },
    ],
    CLIENTS_INSTALMENT_MODAL: [
      {
        no: 0,
        Label: 'form.client.amount',
        name: 'totalAmount',
        id: 'totalAmount',
        type: 'number',
        required: true,
      },
    ],
  },
};

export const CURRANT_USER = {
  user: {},
};

export const DATE_FORMAT = {
  STANDARD_DISPLAY: 'DD-MM-YYYY',
};

export const DISPLAY_TEXT_TYPE = {
  DATE: 'DATE',
  YES_NO: 'YES_NO',
};

export const GENDER = {
  Male: 'M',
  Female: 'F',
  Other: 'O',
};

export const TAB_MENU = {
  USER: [
    {
      no: 0,
      Label: 'Service',
      name: 'service',
      id: 'service',
      tabId: 'service',
    },
    {
      no: 1,
      Label: 'Request',
      name: 'request',
      id: 'request',
      tabId: 'request',
    },
    {
      no: 2,
      Label: 'Transaction',
      name: 'transaction',
      id: 'transaction',
      tabId: 'transaction',
    },
  ],
  SOCEITIES: [
    {
      no: 0,
      Label: 'Towers',
      name: 'tower',
      id: 'tower',
      tabId: 'tower',
    },
    {
      no: 1,
      Label: 'Rooms',
      name: 'room',
      id: 'room',
      tabId: 'room',
    },
    {
      no: 2,
      Label: 'Service',
      name: 'service',
      id: 'service',
      tabId: 'service',
    },
    {
      no: 3,
      Label: 'Parking',
      name: 'parking',
      id: 'parking',
      tabId: 'parking',
    },
  ],
  GIGS: [
    {
      no: 0,
      Label: 'Maid Transaction',
      name: 'transaction',
      id: 'transaction',
      tabId: 'transaction',
    },
    {
      no: 1,
      Label: 'Maid Schedule',
      name: 'schedule',
      id: 'schedule',
      tabId: 'schedule',
    },
    {
      no: 2,
      Label: 'Maid Attendance',
      name: 'attendance',
      id: 'attendance',
      tabId: 'attendance',
    },
    {
      no: 3,
      Label: 'Maid Service',
      name: 'service',
      id: 'service',
      tabId: 'service',
    },
    {
      no: 4,
      Label: 'About Info',
      name: 'info',
      id: 'info',
      tabId: 'info',
    },
  ],
  CLIENTS: [
    {
      no: 0,
      Label: 'card.transaction',
      name: 'transaction',
      id: 'transaction',
      tabId: 'transaction',
    },
    {
      no: 1,
      Label: 'card.instalment',
      name: 'instalment',
      id: 'instalment',
      tabId: 'instalment',
    },
  ],
};

export default CONSTANTS;
